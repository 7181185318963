import { Message } from 'element-ui'
import { message } from './components/resetMessage';
import axios from 'axios'
import config from './config'
// import { resolve } from 'tinymce';

// axios.defaults.baseURL = config.baseURL
let baseUrl=config.baseURL;
let baseUSER_URL=config.baseUSER_URL;

axios.defaults.timeout = 10000

const toType = obj => {
    return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
}

const filterNull = o => {
    if (toType(o) !== 'object' || o.nofilter) return o
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
            if (o[key].length === 0) {
                delete o[key]
            }
        }
    }
    return o
}

const http = {}

http.base = (type, path, params, headers, callback) => {
    const token = localStorage.getItem('token') || ''
    let config = {
        headers: Object.assign(
            {'x-app-info':'1.7.0/iStory/ios','device':'0bbb0eb935754b5486dbebfa38881c02'},
            headers,
            { Token: token },
            { 'Content-Type': 'application/json;charset=UTF-8' }
        ),
        url:path.includes('nc-user-service') ? baseUSER_URL+path :baseUrl+path,
        method: type
    }



    if (['undefined', ''].includes(token)) {
        delete config.headers.Token
    }
    if (type !== 'get') {
        config['data'] = params
    } else {
        params = filterNull(params) || {}
        config['params'] = params
    }
    return new Promise((resolve, reject) => {
        axios(config).then(({ data }) => {
            if (data.code != 0) {
                message.error(data.msg)
                if ([1001002].includes(data.code)) {
                    localStorage.setItem('token', '')
                    localStorage.setItem('userName', '')
                    setTimeout(() => {
                        if (location.pathname != '/authorCenter') {
                            location.href = '/authorCenter'
                        }
                    }, 1500)
                }
                reject(data || { code: -1, msg: 'Network service exception, please try again later' })
            } else {
                resolve(data.data)
            }
            callback && callback(data) // 欢迎使用同步 $get.('/api',params，res=>{})
        })
            .catch((e) => {
                console.log('时间:' + new Date() + ',接口异常: 错误代码,接口地址:' + path);
                message.error('Network service exception, please try again later')
            })
    })
}

http.install = Vue => {
    ;['get', 'post', 'put', 'delete'].forEach(
        h =>
        (Vue.prototype['$' + h] = (url, params, headers, callback) =>
            http.base(h, url, params, headers, callback))
    )
}
export default http
