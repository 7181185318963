<template>
    <div class="full-height">
        <keep-alive>
            <router-view  v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>
<script>
export default {
    name: 'Root',
    data() {
        return {}
    },
    mounted() {
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || /(Android)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        //     // console.log(window.location.href.includes('renewalTerms'), 'href')
        //     if (!(window.location.href.includes('privacyPolicy') || window.location.href.includes('renewalTerms'))) {
        //         window.location.href = "https://wap.novelmanga.com";
        //     }
        // }
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || /(Android)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
            if(location.href.includes('authorCenter')){
                window.location.href = `https://wap.novelmanga.com`;
                return;
            }
            let href=window.location.href,origin=window.location.origin;
            let i=href.slice(origin.length);
            window.location.href = `https://wap.novelmanga.com${i}`;
        }
    },
    computed: {},

    methods: {}
}
</script>
<style>
.el-tooltip__popper {
    max-width: 70%;
}
</style>
