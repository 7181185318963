export const isDev = (/^(192|0|127|localhost|manga.test)/.test(location.hostname) || window.location.href.includes('manga.test')) ? 1 :
/^(author.istorynovel.com)/.test(location.hostname) ? 2 : 3


let i = ''
if (true && /^(192|0|127|localhost|web.manga.test)/.test(location.hostname)) {
  i = 1
} else if (/^(author.istorynovel.com)/.test(location.hostname)) {
  i = 2
} else {
  i = 3
}


export const baseURL = isDev == 1
  ? 'http://api.manga.test/app/auhtor-service/' : isDev == 2 ? 'https://authorapi.istorynovel.com/'
    : 'https://authorapi.novelmanga.com/'

 export const baseUSER_URL = isDev == 1 ? 'http://api.manga.test/' : isDev == 2 ? 'https://mangaapi.istorynovel.com/' : 'https://api.novelmanga.com/'

export const authorUrl=/localhost/.test(location.hostname) ? 'http://localhost:8082/home':  isDev == 1 ? 'http://web.manga.test/home' : 'https://author.novelmanga.com/home'

export const DC = {
  BOOL: {
    0: '否',
    1: '是',
  },
}

export default {
  isDev,
  baseURL,
  DC,
  baseUSER_URL
}
