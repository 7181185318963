import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    userInfo: {},
    // userName: '' || (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).authorName || ''),
    // userImg: '' || (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).avatar || '')
  },
  mutations:{
    saveUserInfo(state,val) {
      state.userInfo = val
    },
    saveUserName(state,val) {
      state.userName = val
    },
    saveUserImg(state,val) {
      state.userImg = val
    },
  }
})
