// import Vue from 'vue'
import { router } from './route'
import { global } from './mixin'

import 'element-ui/lib/theme-chalk/index.css'
import App from 'views/App.vue'
import http from './http'
import { message } from './components/resetMessage';
import FBSignInButton from 'vue-facebook-signin-button'
import 'lib-flexible'
import Vuex from 'vuex'

// import locale from '../node_modules/element-ui/lib/locale/lang/en'
import store from './store/index'
import 'babel-polyfill'

import '@/styles/reset.scss'
import '@/styles/main.scss'
// import '@/assets/icon/iconfont.css'
// import '../theme/index.css'
import {authorUrl} from '@/config'
import {
    Form,
    FormItem,
    Input,
    Checkbox,
    Dialog,
    Button,
    Radio,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    RadioGroup
} from 'element-ui'
import {Encrypt} from '@/utils'
// Vue.use(VueCropper)
Vue.use(http)
// Vue.use(ElementUI, { locale })

Vue.use(Form)
Vue.use( FormItem)
Vue.use( Input)
Vue.use( Button)
Vue.use( Radio)
Vue.use( RadioGroup)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)


Vue.prototype.$message = message

Vue.prototype.toAuthorCenter=function(data){
    setTimeout(()=>{
        window.location.href=`${authorUrl}?p=${Encrypt(JSON.stringify(data))}`
    },1000)
}

Vue.mixin(global)
Vue.use(FBSignInButton)
Vue.use(Vuex)

Vue.prototype.$bus = new Vue();


/***********谷歌登录自定义指令***************/
Vue.directive('google-signin-button', {
    bind: function (el, binding, vnode) {
      CheckComponentMethods()
      let clientId = binding.value
      let googleSignInAPI = document.createElement('script')
      googleSignInAPI.setAttribute('src', 'https://apis.google.com/js/api:client.js')
      document.head.appendChild(googleSignInAPI)

      googleSignInAPI.onload = InitGoogleButton

      function InitGoogleButton() {
        gapi.load('auth2', () => {
          const auth2 = gapi.auth2.init({
            client_id: clientId,
            // cookiepolicy: 'single_host_origin',
            // ux_mode:'redirect'
          })
          auth2.attachClickHandler(el, {},
            OnSuccess,
            Onfail
          )
        })
      }
      function OnSuccess(googleUser) {
        vnode.context.OnGoogleAuthSuccess(googleUser.getAuthResponse().id_token)
        googleUser.disconnect()
      }
      function Onfail(error) {
        vnode.context.OnGoogleAuthFail(error)
      }
      function CheckComponentMethods() {
        if (!vnode.context.OnGoogleAuthSuccess) {
          throw new Error('The method OnGoogleAuthSuccess must be defined on the component')
        }

        if (!vnode.context.OnGoogleAuthFail) {
          throw new Error('The method OnGoogleAuthFail must be defined on the component')
        }
      }
    }
  })

Vue.directive('facebook-signin-button', {
    bind: function (el, binding, vnode) {
      CheckComponentMethods()
      let appId = binding.value
      let facebookSignInAPI = document.createElement('script')
      facebookSignInAPI.setAttribute('src', '//connect.facebook.net/en_US/sdk.js')
      document.head.appendChild(facebookSignInAPI)

      facebookSignInAPI.onload = InitFacebookButton

      function InitFacebookButton() {
        FB.init({
          appId: appId,
          xfbml: true,
          version: 'v10.0',
          display: 'dialog'

        })
        FB.Canvas.setSize({ width: 640, height: 480 });

        // FB.logout()
      }

      el.addEventListener('click', PopUp)

      function PopUp() {
        FB.login(function (facebookResponse) {
          if (facebookResponse.status === 'connected') {
            onSignInSuccess(facebookResponse)
          } else {
            onSignInError()
          }
        })
      }
      function onSignInSuccess(facebookResponse) {
        vnode.context.onSignInSuccess(facebookResponse)
      }
      function onSignInError() {
        vnode.context.onSignInError('Facebook sign-in failed')
      }
      function CheckComponentMethods() {
        if (!vnode.context.onSignInSuccess) {
          throw new Error('The method OnFacebookAuthSuccess must be defined on the component')
        }

        if (!vnode.context.onSignInError) {
          throw new Error('The method OnFacebookAuthFail must be defined on the component')
        }
      }
    }
  })
  Date.prototype.format = function (format) {
    var args = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "H+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),  //quarter
        "S": this.getMilliseconds()
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var i in args) {
        var n = args[i];
        if (new RegExp("(" + i + ")").test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? n : ("00" + n).substr(("" + n).length));
    }
    return format;
}
new Vue({
    router,
    store,
    ...App
}).$mount('#app')
